import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import EditCouponPopUpViewState from './EditCouponPopUpViewState';
import EditCouponPopUpViewProps from './EditCouponPopUpViewProps';
import CouponsOperation from '../../../../../../../../../../../../Transfer/CouponsOperations';
import moment from 'moment';

export default class EditCouponPopUpViewModel extends ContentViewModel<EditCouponPopUpViewState, EditCouponPopUpViewProps>
{
    couponsOperation = new CouponsOperation();

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            coupon: this.props().coupon
        }); // Initialize your state here

        // Bindings
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.handleRateChanged = this.handleRateChanged.bind(this);
        this.handleDateChanged = this.handleDateChanged.bind(this);
        this.handleNumberChanged = this.handleNumberChanged.bind(this);
        this.handleDateCouponChanged = this.handleDateCouponChanged.bind(this);
        this.confirm = this.confirm.bind(this);
        this.deleteCoupon = this.deleteCoupon.bind(this);
    }

    handleStatusChanged(status: any) {
        this.setField((previousState) => previousState.coupon.status = status.value);
    }

    handleRateChanged(rate: any) {
        this.setField((previousState) => previousState.coupon.rate = rate);
    }

    handleDateChanged(text: string) {
        this.setField((previousState) => previousState.coupon.paymentDate = text);
    }

    handleDateCouponChanged(text: string) {
        this.setField((previousState) => previousState.coupon.couponDate = text);
    }

    handleNumberChanged(number: any) {
        this.setField((previousState) => previousState.coupon.number = number);
    }

    confirm() {
        let coupon = this.state().coupon;
        coupon.projectId = this.props().projectId;

        let couponDate = coupon.couponDate;
        coupon.couponDate = moment(couponDate, "DD/MM/YYYY").toJSON();

        if(coupon.paymentDate) {
            let paymentDate = coupon.paymentDate;
            coupon.paymentDate = moment(paymentDate, "DD/MM/YYYY").toJSON();
        }    

        this.couponsOperation.save(coupon, () => {
            this.closePopUp();
            this.props().refresh();
        })
    }

    deleteCoupon() {
        let coupon = this.state().coupon;
        this.couponsOperation.delete(coupon, () => {
            this.closePopUp();
            this.props().refresh();
        })
    }
}