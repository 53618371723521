import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ProjectInfosViewState from './ProjectInfosViewState';
import ProjectInfosViewProps from './ProjectInfosViewProps';
import ProjectInfosViewStyle from './ProjectInfosViewStyle';
import ProjectInfosViewModel from './ProjectInfosViewModel';
import { View, Text, Image, TouchableWithoutFeedback } from 'react-native';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import Select from '../../../../../../../../../../../../Components/Select/Select';
import { TranslationView } from '@levelapp/softfabric-ui';
import ProjectTypeDTO from '../../../../../../../../../../../../DTO/ProjectTypeDTO';
import { TextInput } from 'react-native';
import FileInputView from '../../../../../../../../../../../../Components/FileInput/FileInputView';

export default class ProjectInfosView extends ContentView<ProjectInfosViewProps, ProjectInfosViewState, ProjectInfosViewModel, ProjectInfosViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ProjectInfosViewProps) {
    super(props);

    // Binding
    this.bind(new ProjectInfosViewModel(this), new ProjectInfosViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container, title, containerSameLine, inputInline, input, label, labelInfo, inputText, inputTextDisable } = this.style
    return (
      <View style={container}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "60%" }}>
            <TranslationView style={title}>project.id</TranslationView>
            <View style={[containerSameLine]}>
              <View style={inputInline} >
                <InputView
                  subscribe={() => { }}
                  shouldCheck={false}
                  inputClass="col-lg-7"
                  label={"Id"}
                  labelClass="col-lg-7"
                  onChange={() => { }}
                  disabled={true}
                  value={this.props.project.id}
                  type="text" />
              </View>

              <View style={inputInline}>
                <InputView
                  subscribe={() => { }}
                  shouldCheck={false}
                  inputClass="col-lg-7"
                  label={"Code*"}
                  labelClass="col-lg-7"
                  value={this.props.project.code}
                  onChange={this.props.onCodeChange}
                  disabled={!this.props.isEditable}
                  required={true}
                  type="text" />
              </View>
            </View>

            <View style={input}>
              <InputView
                subscribe={() => { }}
                shouldCheck={false}
                inputClass="col-lg-7"
                label={"Nom de l'émission*"}
                labelClass="col-lg-7"
                onChange={this.props.onProjectNameChange}
                value={this.props.project.projectName}
                disabled={!this.props.isEditable}
                required={true}
                type="text" />
            </View>

            <View style={[inputInline, { zIndex: 9999 }]}>
              <Select
                subscribe={() => { }}
                shouldCheck={false}
                selectClass="col-lg-7"
                label={"Statut du projet*"}
                labelClass="col-lg-7"
                onChange={this.props.onTypeChange}
                disabled={!this.props.isEditable}
                required={true}
                value={this.state.types.find(x => x.id === this.props.project.projectTypeId)?.title}
                options={this.state.types.map((type: ProjectTypeDTO, key) => { return { label: type.title, value: type.id, key } })} />
            </View>

            <View style={[containerSameLine, { zIndex: 500 }]}>
              <View style={[inputInline]}>
                <Select
                  subscribe={() => { }}
                  shouldCheck={false}
                  selectClass="col-lg-12"
                  label={"Est publié ? (API)"}
                  labelClass="col-lg-12"
                  onChange={this.props.onPublishedChange}
                  disabled={!this.props.isEditable}
                  required={true}
                  value={`${this.props.project.isPublished}`}
                  options={[false, true].map((bool: boolean, key) => { return { label: `${bool}`, value: bool, key } })} />
              </View>
              <View style={[inputInline]}>
                <Select
                  subscribe={() => { }}
                  shouldCheck={false}
                  selectClass="col-lg-12"
                  label={"Est visible (Wordpress) ?"}
                  labelClass="col-lg-12"
                  onChange={this.props.onVisibleChange}
                  disabled={!this.props.isEditable}
                  required={true}
                  value={`${this.props.project.isVisible}`}
                  options={[false, true].map((bool: boolean, key) => { return { label: `${bool}`, value: bool, key } })} />
              </View>
            </View>
          </div>
          <div style={{ flex: "40%" }}>
            <View style={{ marginTop: 15 }}>
              <Text style={label}>Commentaire administrateur</Text>
              <TextInput
                multiline={true}
                style={this.props.isEditable ? inputText : inputTextDisable}
                numberOfLines={7}
                editable={this.props.isEditable}
                value={this.props.project.comment}
                onChangeText={(comment: string) => this.props.onCommentChange(comment)} />
            </View>
          </div>
        </div>
        <View style={inputInline}>
          <InputView
            subscribe={() => { }}
            shouldCheck={false}
            inputClass="col-lg-3"
            label={"Catégorie de risque (A-B-C)"}
            labelClass="col-lg-3"
            onChange={this.props.onProjectRiskCategoryChange}
            value={this.props.project.riskCateory}
            disabled={!this.props.isEditable}
            required={true}
            type="text" />
        </View>
        <TranslationView style={title}>projet.description.type</TranslationView>
        <View style={[inputInline, { zIndex: 9999 }]}>
          <Select
            subscribe={() => { }}
            shouldCheck={false}
            selectClass="col-lg-7"
            label={""}
            labelClass="col-lg-7"
            onChange={this.props.onSafetyTypeChange}
            disabled={!this.props.isEditable}
            required={false}
            value={this.state.safetyTypes.find(x => x.id === this.props.project.safetyTypeId)?.title}
            options={this.state.safetyTypes.map((safety: any, key) => { return { label: safety.title, value: safety.id, key } })} />         
        </View>

        {this.props.project.safetyTypeId != 1 ?
          <>
            <TranslationView style={title}>projet.description.safety</TranslationView>
            <TranslationView style={labelInfo}>projet.description.safety.info</TranslationView>

            <View style={containerSameLine}>
              <View style={[inputInline, { width: '30%' }]}>
                <Text style={label}>FR*</Text>
                <TextInput
                  multiline={true}
                  style={this.props.isEditable ? inputText : inputTextDisable}
                  numberOfLines={7}
                  editable={this.props.isEditable}
                  value={this.props.project.safetyDescription.fr}
                  onChangeText={(safetyDescription: string) => this.props.onSafetyDescriptionFRChange(safetyDescription)} />
              </View>
              <View style={[inputInline, { width: '30%' }]}>
                <Text style={label}>NL*</Text>
                <TextInput
                  multiline={true}
                  style={this.props.isEditable ? inputText : inputTextDisable}
                  numberOfLines={7}
                  editable={this.props.isEditable}
                  value={this.props.project.safetyDescription.nl}
                  onChangeText={(safetyDescription: string) => this.props.onSafetyDescriptionNLChange(safetyDescription)} />
              </View>
              <View style={[inputInline, { width: '30%' }]}>
                <Text style={label}>EN*</Text>
                <TextInput
                  multiline={true}
                  style={this.props.isEditable ? inputText : inputTextDisable}
                  numberOfLines={7}
                  editable={this.props.isEditable}
                  value={this.props.project.safetyDescription.en}
                  onChangeText={(safetyDescription: string) => this.props.onSafetyDescriptionENChange(safetyDescription)} />
              </View>
            </View>
          </> :
          <>
          </>}       

        <TranslationView style={title}>projet.description.introduction</TranslationView>
        <TranslationView style={labelInfo}>projet.description.introduction.info</TranslationView>
        <View style={containerSameLine}>
          <View style={[inputInline, { width: '30%' }]}>
            <Text style={label}>FR*</Text>
            <TextInput
              multiline={true}
              style={this.props.isEditable ? inputText : inputTextDisable}
              numberOfLines={7}
              editable={this.props.isEditable}
              value={this.props.project.projectIntroduction.fr}
              onChangeText={(projectIntroduction: string) => this.props.onIntroductionFRChange(projectIntroduction)} />
          </View>
          <View style={[inputInline, { width: '30%' }]}>
            <Text style={label}>NL*</Text>
            <TextInput
              multiline={true}
              style={this.props.isEditable ? inputText : inputTextDisable}
              numberOfLines={7}
              editable={this.props.isEditable}
              value={this.props.project.projectIntroduction.nl}
              onChangeText={(projectIntroduction: string) => this.props.onIntroductionNLChange(projectIntroduction)} />
          </View>
          <View style={[inputInline, { width: '30%' }]}>
            <Text style={label}>EN*</Text>
            <TextInput
              multiline={true}
              style={this.props.isEditable ? inputText : inputTextDisable}
              numberOfLines={7}
              editable={this.props.isEditable}
              value={this.props.project.projectIntroduction.en}
              onChangeText={(projectIntroduction: string) => this.props.onIntroductionENChange(projectIntroduction)} />
          </View>
        </View>

        <TranslationView style={title}>projet.multimedia.picture</TranslationView>

        <View style={[containerSameLine, { alignItems: 'center' }]}>
          {this.props.project.coverPictureUrl &&
            <View style={{ margin: "1%" }}>
              <Text style={label}>Photo de la carte et dans le détail du projet</Text>
              <img src={this.props.project.coverPictureUrl} />
              <TouchableWithoutFeedback onPress={this.binding.showCoverPic}>
                <Image source={{ uri: this.props.project.coverPictureUrl }} style={{ height: 200, width: 300, resizeMode: 'contain' }} />
              </TouchableWithoutFeedback>
            </View>
          }
          {this.props.isEditable &&
            <View style={input}>
              <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={"Photo de la carte et dans le détail du projet"} onChange={(file: File) => { this.binding.handleFileChanged(file) }} placeholder={"Téléchargez une photo"} file={this.state.coverFile} accept="image/*, .pdf" />
            </View>}
        </View>
      </View>
    );
  }
}