import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import ProjectInvestorsViewState from './ProjectInvestorsViewState';
import ProjectInvestorsViewProps from './ProjectInvestorsViewProps';
import ProjectsInvestorsPopUpView from './modules/projectsinvestorspopup/ProjectsInvestorsPopUpView';
import React from 'react';
import InvestmentsOperation from '../../../../../../../../../../../Transfer/InvestmentsOperations';
import DropDownView from '../../../../../../../../../../../Components/Dropdown/DropDownView';
import { Text, TouchableWithoutFeedback, Image, View } from 'react-native';
import * as XLSX from 'xlsx';
import ProjectInvestementStatusUpdatedView from '../../../../../../../../../../../Components/projectinvestementstatusupdated/ProjectInvestementStatusUpdatedView';
import ProjectsViewStyle from '../../../../../ProjectsViewStyle';
import StateManager from '../../../../../../../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../../../../../../../Common/Managers/StateManagerConst';
import CertificatesOperation from '../../../../../../../../../../../Transfer/CertificatesOperations';
import { ButtonView } from '@levelapp/softfabric-ui';
import SendEmailCouponCourantPopUpView from '../../../../../../../users/modules/edituser/modules/profile/modules/userinvestments/modules/sendemailcouponcourantpopup/SendEmailCouponCourantPopUpView';
import MailSendedPopUpView from '../../../../../../../users/modules/edituser/modules/profile/modules/userinvestments/modules/mailsendedpopup/MailSendedPopUpView';
import PaymentInformationOperation from '../../../../../../../../../../../Transfer/PaymentInformationOperation';
import ProjectsOperation from '../../../../../../../../../../../Transfer/ProjectsOperations';
import FileBankInformationPopUpView from './modules/filebankinformationpopup/FileBankInformationPopUpView';
import RoleManager from '../../../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../../../Common/Managers/ExtendedManagers';
import PrimePopUpView from './modules/primepopup/PrimePopUpView';
import moment from 'moment';
import BankPaymentImportPopUpView from './modules/bankpaymentimportpopup/BankPaymentImportPopUpView';
import SendEmailToPendingPopUpView from './modules/sendemailtopendingpopup/SendEmailToPendingPopUpView';
import UsersOperation from '../../../../../../../../../../../Transfer/UsersOperation'; 
export default class ProjectInvestorsViewModel extends ContentViewModel<ProjectInvestorsViewState, ProjectInvestorsViewProps, ProjectsViewStyle>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    investementsOperation = new InvestmentsOperation();
    certificatesOperation = new CertificatesOperation();
    paymentInformationOperation = new PaymentInformationOperation();
    projectOperation = new ProjectsOperation();
    usersOperation = new UsersOperation();
    textResearch: string = "";
    interval: any;

    dropdownValues = [
        {
            title: "Pending",
            value: false
        },
        {
            title: "Validated",
            value: true
        },
    ];

    constructor(props: ContentView) {
        super(props);
        var project = this.parameters<any>();
        /* Initial State */
        let previousState = this.StateManager.retrieveState(StateManagerConst.INVESTMENT_PROJECT);

        this.initialState({
            project: project,
            tableHeaders: [],
            isLoading: false,
            
            investors: [],
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            currentPage: 1,
            isResearching: false,
            nbPage: 0,
            isExporting: false,
            selectedStatus: previousState != undefined ? previousState.selectedStatus : { id: 0, title: "All" },
            statuses: [{ id: 0, title: "All" }, { id: 1, title: "Pending" }, { id: 2, title: "Validated" }],
            isAdmin: this.RoleManager.isAdmin,
            isExport: this.RoleManager.isExport,
            isExportLoading: false
        })

        this.ValidInvestment = this.ValidInvestment.bind(this);
        this.ValidInvestmentService = this.ValidInvestmentService.bind(this);
        this.exportInvestors = this.exportInvestors.bind(this);
        this.updateInvestmentStatus = this.updateInvestmentStatus.bind(this);
        this.updateSelectStatuses = this.updateSelectStatuses.bind(this);
        this.getSelectedValue = this.getSelectedValue.bind(this);
        this.handleSuccessInvestmentCallback = this.handleSuccessInvestmentCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.sendEmailCouponCourantPopUp = this.sendEmailCouponCourantPopUp.bind(this);

        this.handleFileBankInformationPopUp = this.handleFileBankInformationPopUp.bind(this);
        this.exportRegisterInterests = this.exportRegisterInterests.bind(this);
        this.exportRegister = this.exportRegister.bind(this);
        this.SendEmailToPending = this.SendEmailToPending.bind(this);

        this.showPrimePopUp = this.showPrimePopUp.bind(this);
        this.showBankDataPayementImportPopUp = this.showBankDataPayementImportPopUp.bind(this);

        this.exportMoneyTrans = this.exportMoneyTrans.bind(this);

        this.handleFileBankInformationEndEmail = this.handleFileBankInformationEndEmail.bind(this)
    }
    editField(userId: any) {

        this.usersOperation.getUserById(userId, (user : any) =>{
            this.NavigationManager.navigate('/dashboard/users/edit', true,user);
        })
        
    }

    componentDidMount() {
        this.refresh();
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    refresh() {
        this.setField({ isLoading: true });

        this.investementsOperation.get(this.state().selectedStatus.title, this.state().project.id, this.state().currentPage, 10, this.textResearch, this.handleSuccessInvestmentCallback);
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(investment => {

            this.pushRow(investment, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }
    exportMoneyTrans(){
        if(!this.state().project.bce) {
            alert("Veuillez remplire le code bce");
            return;
        }
        if(!this.state().project.address || !this.state().project.address.country) {
            alert("Veuillez remplire l'adresse");
            return;
        }
        let id = this.state().project.id;
        this.setField({isExportLoading: true}, () => {
            this.projectOperation.exportMoneyTrans(id, (response: any) => {
                const url = window.URL.createObjectURL(new Blob([this.b64DecodeUnicode(response.data)], {type: 'text/xml'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Export MoneyTrans_' + this.state().project.code + '.xml');
                document.body.appendChild(link);
                link.click();
                this.setField({isExportLoading: false})
            })
        })
    }
    b64DecodeUnicode(str:any) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }
    exportRegister(){
        let id = this.state().project.id;
        this.setField({isExportLoading: true}, () => {
            this.projectOperation.exportRegister(id, (response: any) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Registre des obligations_' + this.state().project.code + '.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setField({isExportLoading: false})
            })
        })

    }

    exportRegisterInterests(){
        let id = this.state().project.id;
        this.setField({isExportLoading: true}, () => {
            this.projectOperation.exportRegisterInterests(id, (response: any) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Données de résidences et bancaires_' + this.state().project.code + '.xlsx');
                document.body.appendChild(link);
                link.click();
                this.setField({isExportLoading: false})
            })
        })
    }

    pushRow(investment: any, rowsToPush: any[]) {
        let myInvestment = this.state().investors.find((investmentFull) => (investmentFull.id === investment.id));
        var momentDate = moment.utc(investment.creationDate);
        var dateAsString = momentDate.local().format('DD-MM-YYYY  H:mm:ss')

        var momentModifDate = moment.utc(investment.LastModificationDate);
        var modifDateAsString = momentModifDate.local().format('DD-MM-YYYY  H:mm:ss')

        if (this.state().isAdmin || this.RoleManager.isEditor) {
            rowsToPush.push(
                <View>
                    <Text style={this.style().text} >{investment.id}</Text>
                    <Text style={this.style().text} >{investment.aspNetUserId}</Text>
                    <Text style={this.style().text} >{investment.user.clientNumber}</Text>
                    <Text style={this.style().text} >{investment.legalEntity!=null ? investment.legalEntity.corporateName : "Aucune"}</Text>
                    <Text style={this.style().text}>{investment.lastName}</Text>
                    <Text style={this.style().text}>{investment.email}</Text>
                    <Text style={this.style().text}>{investment.amount}</Text>
                    <Text style={this.style().text} >{investment.isWaiting ? 'OUI': 'NON'}</Text>
                    <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <DropDownView width={100} onValueChange={(value: any) => { this.updateSelectStatuses(value, myInvestment) }} selectedValue={this.getSelectedValue(myInvestment)} values={this.dropdownValues}></DropDownView>
                    </View>
                    <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(myInvestment) }} >
                        <Image style={{ alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                    </TouchableWithoutFeedback>
                    <Text style={this.style().text}>{dateAsString}</Text>
                    <Text style={this.style().text}>{modifDateAsString}</Text>
                    {investment.isCouponCourus && investment.interests == 0 && !investment.isValidated &&
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showSendEmailCouponCourantPopUp(investment) }}>
                                <Text style={this.style().text} >Envoyer email</Text>
                            </ButtonView>
                        }
                </View>
            )
        }
        else{
            rowsToPush.push(
                <View>
                    <Text style={this.style().text} >{investment.id}</Text>
                    <Text style={this.style().text} >{investment.aspNetUserId}</Text>
                    <Text style={this.style().text} >{investment.user.clientNumber}</Text>
                    <Text style={this.style().text} >{investment.legalEntity!=null ? investment.legalEntity.corporateName : "Aucune"}</Text>
                    <Text style={this.style().text}>{investment.lastName}</Text>
                    <Text style={this.style().text}>{investment.amount}</Text>
                    <Text style={this.style().text} >{investment.isWaiting ? 'OUI': 'NON'}</Text>
                    <Text style={this.style().text}>{this.getSelectedValue(investment).title}</Text>
                    <Text style={this.style().text}>X</Text>
                    <Text style={this.style().text}>{dateAsString}</Text>
                    <Text style={this.style().text}>{modifDateAsString}</Text>
                    {investment.isCouponCourus && investment.interests == 0 &&
                            <Text></Text>
                        }
                </View>
            )
        }
    }

    ValidInvestment(AspNetUserId: any) {
        this.showPopUp(
            <ProjectsInvestorsPopUpView callBack={() => { this.ValidInvestmentService(AspNetUserId) }} />
        );
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value }, () => {
            if (value.title == "ALL" && this.state().text == "") {
                this.handlingClose();
            } else {
                this.handlerResearching(this.state().text);
            }
        });
    }

    ValidInvestmentService(asp: any) {
        let user = { AspNetUserId: asp };

        this.investementsOperation.validUser(user, () => {
            this.NavigationManager.navigate('/dashboard/projects');
        });
    }

    handleSuccessInvestmentCallback(ret: any) {
        let investments: any[] = ret.objects;
        let datas: any[] = [];
        let rows: any[] = [];
        investments.forEach((investment) => {
            let investmentDTO = {
                "aspNetUserId": investment.userId,
                "clientNumber": investment.user.clientNumber,
                "lastName": investment.user.lastName,
                "email": investment.user.email,
                "amount": investment.amount,
                "isValidatedString": this.getSelectedValue(investment).title,
                "userId": investment.userId,
                "creationDate": investment.creationDate,
                "language": investment.user.language,
                "id": investment.id,
                "isCouponCourus": investment.isCouponCourus,
                "interests": investment.interests,
                "LastModificationDate": investment.lastModificationDate,
                "isWaiting" : investment.isWaiting,
                "isPrime" : investment.isPrime,
            }
            var momentDate = moment.utc(investmentDTO.creationDate);
            var dateAsString = momentDate.local().format('DD-MM-YYYY  H:mm:ss ')

            var momentModifDate = moment.utc(investmentDTO.LastModificationDate);
            var modifDateAsString = momentModifDate.local().format('DD-MM-YYYY  H:mm:ss ')

            datas.push(investmentDTO);

            if (this.state().isAdmin || this.RoleManager.isEditor) {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text} >{investmentDTO.aspNetUserId}</Text>
                        <Text style={this.style().text} >{investmentDTO.clientNumber}</Text>
                        <Text style={this.style().text} >{investment.legalEntity!=null ? investment.legalEntity.corporateName : "Aucune"}</Text>
                        <Text style={this.style().text}>{investmentDTO.lastName}</Text>

                        
                        <TouchableWithoutFeedback onPress={() => { this.editField(investmentDTO.aspNetUserId) }} >
                        <View style={ this.style().text } >
                            <Text style={this.style().text}>{investmentDTO.email}</Text>
                        </View>
                        </TouchableWithoutFeedback>



                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI': 'NON'}</Text>
                        <DropDownView width={100}onValueChange={(value: any) => { this.updateSelectStatuses(value, investment) }} selectedValue={this.getSelectedValue(investment)} values={this.dropdownValues}></DropDownView>
                        <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(investment) }} >
                            <Image style={{ alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                        </TouchableWithoutFeedback>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text}>{modifDateAsString}</Text>
                        {investmentDTO.isCouponCourus && investmentDTO.interests == 0 && !investment.isValidated &&
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showSendEmailCouponCourantPopUp(investment) }}>
                                <Text style={this.style().text} >Envoyer email</Text>
                            </ButtonView>
                        }
                    </View>
                )
            }
            else {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text} >{investmentDTO.aspNetUserId}</Text>
                        <Text style={this.style().text} >{investmentDTO.clientNumber}</Text>
                        <Text style={this.style().text} >{investment.legalEntity!=null ? investment.legalEntity.corporateName : "Aucune"}</Text>
                        <Text style={this.style().text}>{investmentDTO.lastName}</Text>
                        <Text style={this.style().text}>{investmentDTO.email}</Text>
                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI': 'NON'}</Text>
                        <Text style={this.style().text}>{this.getSelectedValue(investment).title}</Text>
                        <Text style={this.style().text}>X</Text>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text}>{modifDateAsString}</Text>
                        {investmentDTO.isCouponCourus && investmentDTO.interests == 0 &&
                            <Text></Text>
                        }
                    </View>
                )
            }
        });
        this.setField({ datas: datas, rows: rows, initialData: datas, initialRow: rows, investors: this.state().project.investments });
        this.setField({ tableHeaders: ['INVEST ID','USER ID','CLIENT NUMBER',"P.M", 'NOM', 'EMAIL', 'MONTANT', 'IS WAITING','STATUT', 'CHANGE STATUS', 'DATE', 'MODIF DATE', 'COUPON COURRU'] });

        this.setField({ nbPage: ret.totalPage });
        this.setField({ isLoading: false });
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    resetTable() {
        if (this.state().text == "")
            this.handlingClose();
        else (this.handlerResearching(this.state().text))
    }

    handlerResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true, text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.refresh()
        }, 1000)
    }

    handlingClose() {
        this.state().isResearching = false;
        this.textResearch = "";
        this.onPageChange(0);
    }

    componentWillUnmount() {
        this.StateManager.addState(StateManagerConst.INVESTMENT_PROJECT, this.state());
    }

    updateSelectStatuses(value: any, invest: any) {
        invest.isValidated = value.value;
    }

    updateInvestmentStatus(investment: any) {
        new InvestmentsOperation().updateInvestmentStatus(investment, () => { })
            .then(() => {
                this.showPopUp(
                    <ProjectInvestementStatusUpdatedView />
                );
            }).then(() => {
                setTimeout(() => this.closePopUp(), 3000);
            }).then(() => {
                setTimeout(() => this.refresh(), 2000);
            });;
    }

    getSelectedValue(invest: any) {
        let index = invest.isValidated == true ? 1 : 0;
        return this.dropdownValues[index];
    }

    exportInvestors() {
        var headers = ['UserId', 'GUID', 'Number', 'Amount', 'Iban', 'Civility', 'Firstname', 'Name', 'Email', 'Date', 'Status', 'IsWaiting', 'Personne Morale', 'Langue', 'HowDoIHeardAboutBeeBonds'];
        var jsonContent: any[] = [];
        this.setField({ isExporting: true });
        this.investementsOperation.get(this.state().selectedStatus.title, this.state().project.id, 1, 1000, this.textResearch, (datas: any) => {           
            datas.objects.forEach((value: any) => {     
                var element = "{";
                var row = "";
                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.user.id);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.user.aspNetUserId);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(value.user.clientNumber);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.amount);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.user.iban);
                row += ",";
                row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.user.civility);
                row += ",";
                row += JSON.stringify(headers[6]) + ":" + JSON.stringify(value.user.firstName);
                row += ",";
                row += JSON.stringify(headers[7]) + ":" + JSON.stringify(value.user.lastName);
                row += ",";
                row += JSON.stringify(headers[8]) + ":" + JSON.stringify(value.user.email);
                row += ",";
                row += JSON.stringify(headers[9]) + ":" + JSON.stringify(value.creationDate);
                row += ",";
                row += JSON.stringify(headers[10]) + ":" + JSON.stringify((value.isValidated) ? "VALIDATED" : "PENDING");
                row += ",";
                row += JSON.stringify(headers[11]) + ":" + JSON.stringify((value.isWaiting) ? "WAITING" : "-");
                row += ",";
                row += JSON.stringify(headers[12]) + ":" + JSON.stringify((value.legalEntity != null) ? value.legalEntity.corporateName : "Aucune");
                row += ",";
                row += JSON.stringify(headers[13]) + ":" + JSON.stringify(value.user.language);
                row += ",";
                row += JSON.stringify(headers[14]) + ":" + JSON.stringify(value.user.howDoIHeardAboutBeeBonds);
                element = element + row;
                element += "}";                
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, this.state().project.code.toUpperCase());
            XLSX.writeFile(wb, "BeeBondsInvestors.xlsx");

            this.setField({ isExporting: false });
        });
    }



    handleFileBankInformationPopUp() {
        this.showPopUp(
            <FileBankInformationPopUpView 
            projectCode= {this.state().project.code} />
        );
        this.refresh();
    }

    handleFileBankInformationEndEmail(test:boolean) {
        var model = {
            "ProjectCode" : "demo7",
            "SendTest" : test,
        }
        this.paymentInformationOperation.sendPaymentConfirmationEmail(model, () => {
            alert("Les émails ont été envoyés");
        });
    }
 

    

    showSendEmailCouponCourantPopUp(investment: any) {
        this.showPopUp(<SendEmailCouponCourantPopUpView 
            project={this.props().project} 
            callBack={this.sendEmailCouponCourantPopUp} 
            investment={investment}></SendEmailCouponCourantPopUpView>);
    }

    sendEmailCouponCourantPopUp(investment: any) {
        investment.Project = this.state().project;     
        //investment.Interests = investment.Interests.replace(",", "");        
        new InvestmentsOperation().sendEmailCouponCourant(investment)
            .then(() => {
                this.showPopUp(
                    <MailSendedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/users/', true);
            });
    }

    

    SendEmailToPending() {
        this.showPopUp(
            <SendEmailToPendingPopUpView project ={this.state().project} />
        );      
    }   

    showPrimePopUp(){
        this.showPopUp(
            <PrimePopUpView 
            projectId= {this.state().project.id} />
        );
    }
    showBankDataPayementImportPopUp(){
        this.showPopUp(
            <BankPaymentImportPopUpView 
            projectId= {this.state().project.id}
            handleBackPressed = {this.refresh} />
        );
    }
}