import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import MultimediaViewState from './MultimediaViewState';
import MultimediaViewProps from './MultimediaViewProps';
import MultimediaViewStyle from './MultimediaViewStyle';
import MultimediaViewModel from './MultimediaViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { TouchableWithoutFeedback, View, Text } from 'react-native';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import FileInputView from '../../../../../../../../../../../../Components/FileInput/FileInputView';

export default class MultimediaView extends ContentView<MultimediaViewProps, MultimediaViewState, MultimediaViewModel, MultimediaViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: MultimediaViewProps) {
    super(props);

    // Binding
    this.bind(new MultimediaViewModel(this), new MultimediaViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { title, containerSameLine, input, label, link, inputFile } = this.style
    return (
      <View>

        <TranslationView style={title}>projet.multimedia.pdf</TranslationView>

        <View style={inputFile}>
          {this.props.project.confirmationPdfUrl.length > 0 && <View style={{ margin: "1%" }}>
            <Text style={label}>Pdf de confirmation FR</Text>
            <TouchableWithoutFeedback onPress={() => window.open(this.props.project.confirmationPdfUrl.replace("{0}", "FR"))}>
              <View><Text style={link}>{this.props.project.confirmationPdfUrl.replace("{0}", "FR")}</Text></View>
            </TouchableWithoutFeedback>
          </View>}
          <View style={input}>
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={"Pdf de confirmation FR"} onChange={(file: File) => { this.binding.handlePdfFileFRChanged(file) }} placeholder={"Téléchargez un PDF"} file={this.state.PdfFileFR} accept=".Pdf" />
          </View>
        </View>

        <View style={inputFile}>
          {this.props.project.confirmationPdfUrl.length > 0 && <View style={{ margin: "1%" }}>
            <Text style={label}>Pdf de confirmation FR</Text>
            <TouchableWithoutFeedback onPress={() => window.open(this.props.project.confirmationPdfUrl.replace("{0}", "NL"))}>
              <View><Text style={link}>{this.props.project.confirmationPdfUrl.replace("{0}", "NL")}</Text></View>
            </TouchableWithoutFeedback>
          </View>}
          <View style={input}>
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={"Pdf de confirmation NL"} onChange={(file: File) => { this.binding.handlePdfFileNLChanged(file) }} placeholder={"Téléchargez un PDF"} file={this.state.PdfFileNL} accept=".Pdf" />
          </View>
        </View>

        <TranslationView style={title}>projet.multimedia.video</TranslationView>
        <View style={containerSameLine}>
          <View style={input}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Url de la vidéo (embedded)"}
              labelClass="col-lg-7"
              value={this.props.project.videoUrl}
              onChange={this.props.onVideoUrlChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>
        <TranslationView style={title}>projet.multimedia.certicates</TranslationView>
        <View>
          {false &&
            <View style={{ margin: "1%" }}>
              <Text style={label}>Logo</Text>
            </View>
          }
        </View>

        {this.props.isEditable &&
          <View style={input}>
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={"Logo"} onChange={(file: File) => { this.binding.handleFileLogoChanged(file) }} placeholder={"Téléchargez une photo"} file={this.state.certificateLogoFile} accept=".png" />
          </View>}
        <View>
          {false &&
            <View style={{ margin: "1%" }}>
              <Text style={label}>Signature</Text>
            </View>
          }
        </View>
        {this.props.isEditable &&
          <View style={input}>
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={"Signature"} onChange={(file: File) => { this.binding.handleFileSignatureChanged(file) }} placeholder={"Téléchargez une photo"} file={this.state.certificateSignatureFile} accept=".png" />
          </View>}
      </View>
    );
  }
}