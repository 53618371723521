import { ContentViewModel, ContentView, Application, Managers, NavigationManager } from '@levelapp/softfabric';
import InformationsViewState from './InformationsViewState';
import InformationsViewProps from './InformationsViewProps';
import FIELDS from './fields/Fields';
import ProjectDTO from '../../../../../../../../../../DTO/ProjectDTO';
import ProjectsOperations from '../../../../../../../../../../Transfer/ProjectsOperations';
import React from 'react';
import ValidationPopUpView from '../../../../../../../../../../Common/PopUp/validationPopUpView/ValidationPopUpView';
import TranslationDTO from '../../../../../../../../../../DTO/TranslationDTO';
import { View, Text } from 'react-native';
import moment from 'moment';
import RoleManager from '../../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../../Common/Managers/ExtendedManagers';
export default class InformationsViewModel extends ContentViewModel<InformationsViewState, InformationsViewProps>
{
  /* CONSTRUCTOR */
  ProjectsOperations = new ProjectsOperations()
  NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
  RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)



  constructor(props: ContentView) {
    super(props);


    this.initialState({
      canEdit: this.RoleManager.isAdmin || this.RoleManager.isEditor ? true : false,
      isLoading: false,
      isEditable: true,
      isNewProject: true,
      selectedBank: '',
      currentTab: FIELDS.PROJECT_INFOS,
      openingHours: "11:00",
      project: {
        //Project info
        id: 0,
        isPublished: false,
        projectOrder: 0,
        code: '',
        projectName: '',
        projectTypeId: 0,
        safetyTypeId: 1,
        placementType1Id: 1,
        placementType2Id: 1,
        investments : null,

        //finance info
        minimumInvest: 0,
        maximumInvest: 0,
        currentInvest: 0,
        annualInterestRate: 0,
        annualInterestRateString: "",
        overbookingRate: 1,
        duration: 0,
        durationString: "",
        minimumTicket: 100,
        maximumTicket: 0,
        currentInvestMax100: 0,
        issueDate: '',
        dueDate: '',
        openingDate: new Date(),
        iban: '',
        bic: '',
        isin: '',
        bce: '',
        riskCateory: '',

        //Company info
        enterprise: '',
        activity: {
          id: 0,
          fr: '',
          nl: '',
          en: '',
        },
        activityId: 0,
        startOfActivity: 0,
        fullCompanyName: '',
        consoleTitle: '',
        name: {
          fr: '',
          nl: '',
          en: '',
        },
        nameId: 0,
        addressFR: '',
        addressNL: '',
        addressId: 0,
        address: {
          street: '',
          box: '',
          postCode: '',
          locality: '',
          country: '',
          number: '',
        },
        enterpriseWebSite: '',

        //Description
        projectIntroduction: {
          fr: '',
          nl: '',
          en: '',
        },
        projectIntroductionId: 0,
        projectDescription: {
          fr: '',
          nl: '',
          en: '',
        },
        projectDescriptionId: 0,
        projectHeader: {
          fr: '',
          nl: '',
          en: '',
        },
        projectHeaderId: 0,
        documentsLegalInfoText: {
          fr: '',
          nl: '',
          en: '',
        },
        documentsLegalInfoTextId: 0,
        aboutLegalInfoText: {
          fr: '',
          nl: '',
          en: '',
        },
        aboutLegalInfoTextId: 0,
        confirmationPdfUrl: "",
        projectBenefits: [],
        projectQuestions: [],
        leftMenuPictureUrl: "https://beebondsstorage.blob.core.windows.net/files/shared/menuBackground.png",
        certificateLogo: "",
        certificateSignature: "",
        offerEndDate: '',
        comment: '',
        leaderId: 1,

        safetyDescription: {
          fr: '',
          nl: '',
          en: '',
        },
        isSafety: false,
        isVisible: false,

        legalForm: null,
        legalFormId: 0,

      }
    })

    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleSaveButtonPressed = this.handleSaveButtonPressed.bind(this)
    this.handleEditButtonPressed = this.handleEditButtonPressed.bind(this)

    //Project Infos
    this.handleProjectNameChange = this.handleProjectNameChange.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.handleIsPublishedChange = this.handleIsPublishedChange.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleProjectRiskCategoryChange = this.handleProjectRiskCategoryChange.bind(this);

    this.handleSafetyDescriptionFRChange = this.handleSafetyDescriptionFRChange.bind(this);
    this.handleSafetyDescriptionNLChange = this.handleSafetyDescriptionNLChange.bind(this);
    this.handleSafetyDescriptionENChange = this.handleSafetyDescriptionENChange.bind(this);

    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.handleIsSafety = this.handleIsSafety.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSafetyTypeChange = this.handleSafetyTypeChange.bind(this);

    //Finances Infos
    this.handleMinimumInvestChange = this.handleMinimumInvestChange.bind(this)
    this.handleMaximumInvestChange = this.handleMaximumInvestChange.bind(this)
    this.handleCurrentInvestChange = this.handleCurrentInvestChange.bind(this)
    this.handleMinimumTicketChange = this.handleMinimumTicketChange.bind(this)
    this.handleMaximumTicketChange = this.handleMaximumTicketChange.bind(this);
    this.handleDurationChange = this.handleDurationChange.bind(this)
    this.handleIssueDateChange = this.handleIssueDateChange.bind(this)
    this.handleDueDateChange = this.handleDueDateChange.bind(this);
    this.handleOpeningDateChange = this.handleOpeningDateChange.bind(this)
    this.handleAnnualInterestRateChange = this.handleAnnualInterestRateChange.bind(this)
    this.handleOverbookingRateChange = this.handleOverbookingRateChange.bind(this)
    this.handleIBANChange = this.handleIBANChange.bind(this)
    this.handleBICChange = this.handleBICChange.bind(this)
    this.handleBCEChange = this.handleBCEChange.bind(this)
    this.handleISINChange = this.handleISINChange.bind(this)
    this.handleBankChange = this.handleBankChange.bind(this)
    this.handleOpeningHoursChange = this.handleOpeningHoursChange.bind(this)
    this.handleOfferEndDateChange = this.handleOfferEndDateChange.bind(this)
    this.handlePlacementTypeChange = this.handlePlacementTypeChange.bind(this);
    this.handleObligationFormTypeChange = this.handleObligationFormTypeChange.bind(this);

    //Company Infos
    this.handleNameFRChange = this.handleNameFRChange.bind(this)
    this.handleNameNLChange = this.handleNameNLChange.bind(this)
    this.handleNameENChange = this.handleNameENChange.bind(this)
    this.handleActivityDropdownChange = this.handleActivityDropdownChange.bind(this)
    this.handleActivityFRChange = this.handleActivityFRChange.bind(this)
    this.handleActivityNLChange = this.handleActivityNLChange.bind(this)
    this.handleActivityENChange = this.handleActivityENChange.bind(this)
    this.handlestartOfActivityChange = this.handlestartOfActivityChange.bind(this)
    this.handleStreetChange = this.handleStreetChange.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.handleBoxChange = this.handleBoxChange.bind(this)
    this.handlePostCodeChange = this.handlePostCodeChange.bind(this)
    this.handleLocalityChange = this.handleLocalityChange.bind(this)
    this.handleCountryChange = this.handleCountryChange.bind(this)
    this.handleWebsiteURLChange = this.handleWebsiteURLChange.bind(this)
    this.handleFullAddressFRChange = this.handleFullAddressFRChange.bind(this)
    this.handleFullAddressNLChange = this.handleFullAddressNLChange.bind(this)
    this.handleLeaderChange = this.handleLeaderChange.bind(this);
    this.convertStringToNumber = this.convertStringToNumber.bind(this);
    this.onLegalFormChange = this.onLegalFormChange.bind(this);

    //Description
    this.handleIntroductionFRChange = this.handleIntroductionFRChange.bind(this)
    this.handleIntroductionNLChange = this.handleIntroductionNLChange.bind(this)
    this.handleIntroductionENChange = this.handleIntroductionENChange.bind(this)
    this.handleDescriptionFRChange = this.handleDescriptionFRChange.bind(this)
    this.handleDescriptionNLChange = this.handleDescriptionNLChange.bind(this)
    this.handleDescriptionENChange = this.handleDescriptionENChange.bind(this)
    this.handleHeaderFRChange = this.handleHeaderFRChange.bind(this)
    this.handleHeaderNLChange = this.handleHeaderNLChange.bind(this)
    this.handleHeaderENChange = this.handleHeaderENChange.bind(this)
    this.handleAboutLegalTextFRChange = this.handleAboutLegalTextFRChange.bind(this)
    this.handleAboutLegalTextNLChange = this.handleAboutLegalTextNLChange.bind(this)
    this.handleAboutLegalTextENChange = this.handleAboutLegalTextENChange.bind(this)
    this.handleDocumentLegalTextFRChange = this.handleDocumentLegalTextFRChange.bind(this)
    this.handleDocumentLegalTextNLChange = this.handleDocumentLegalTextNLChange.bind(this)
    this.handleDocumentLegalTextENChange = this.handleDocumentLegalTextENChange.bind(this)

    //Multimedia
    this.handlePreviewPictureChange = this.handlePreviewPictureChange.bind(this)
    this.handleLeftMenuPictureUrlChange = this.handleLeftMenuPictureUrlChange.bind(this)
    this.handleCoverPictureChange = this.handleCoverPictureChange.bind(this)
    this.handleLogoPictureChange = this.handleLogoPictureChange.bind(this)
    this.handleVideoUrlChange = this.handleVideoUrlChange.bind(this)
    this.handlePdfFRChange = this.handlePdfFRChange.bind(this)
    this.handlePdfNLChange = this.handlePdfNLChange.bind(this)
    this.handleCertificateLogoFileChange = this.handleCertificateLogoFileChange.bind(this)
    this.handleCertificateSignatureFileChange = this.handleCertificateSignatureFileChange.bind(this)

    //Benefits
    this.handleBenefitsOrderChange = this.handleBenefitsOrderChange.bind(this)
    this.handleBenefitsTitleFRChange = this.handleBenefitsTitleFRChange.bind(this)
    this.handleBenefitsTitleNLChange = this.handleBenefitsTitleNLChange.bind(this)
    this.handleBenefitsTitleENChange = this.handleBenefitsTitleENChange.bind(this)
    this.handleBenefitsSubtitleFRChange = this.handleBenefitsSubtitleFRChange.bind(this)
    this.handleBenefitsSubtitleNLChange = this.handleBenefitsSubtitleNLChange.bind(this)
    this.handleBenefitsSubtitleENChange = this.handleBenefitsSubtitleENChange.bind(this)

    //Benefits
    this.handleQuestionsOrderChange = this.handleQuestionsOrderChange.bind(this)
    this.handleQuestionsTitleFRChange = this.handleQuestionsTitleFRChange.bind(this)
    this.handleQuestionsTitleNLChange = this.handleQuestionsTitleNLChange.bind(this)
    this.handleQuestionsTitleENChange = this.handleQuestionsTitleENChange.bind(this)
    this.handleQuestionsResponseFRChange = this.handleQuestionsResponseFRChange.bind(this)
    this.handleQuestionsResponseNLChange = this.handleQuestionsResponseNLChange.bind(this)
    this.handleQuestionsResponseENChange = this.handleQuestionsResponseENChange.bind(this)

    this.callback= this.callback.bind(this);

  }

  componentDidMount() {
    if (this.parameters<any>().newProject === undefined) {
      var project: ProjectDTO = this.parameters<any>();
      if (project.safetyDescription === null) {
        project.safetyDescription = this.state().project.safetyDescription;
      }
      if (project.duration) {
        project.durationString = project.duration.toString();
      }
      if (project.annualInterestRate) {
        project.annualInterestRateString = project.annualInterestRate.toString();
      }
      this.setField((previousState) => previousState.project = project);
      this.setField((previousState) => previousState.project.openingDate = project.openingDate);
      this.setField((previousState) => previousState.openingHours = project.openingDate.toString().split('T')[1].split(':').slice(0, 2).join(':'));
      this.setField((previousState) => previousState.isNewProject = false);
      this.setField((previousState) => previousState.isEditable = false);
      this.setField((previousState) => previousState.isLoading = true);

      this.ProjectsOperations.getBenefitsQuestions(project.id, (BenefitsQuestions: any) => {
        this.setField((previousState) => previousState.project.projectBenefits = BenefitsQuestions.item2);
        this.setField((previousState) => previousState.project.projectQuestions = BenefitsQuestions.item1);
        this.setField((previousState) => previousState.isLoading = false);
      })
    }
    // New project
    else {
      let p = this.state().project;
      p.aboutLegalInfoText.fr = "Communication à caractère promotionnel – Souscrire à une Levée de Fonds comporte pour l'investisseur un risque de perte totale du capital investi. Avant d'investir, veuillez lire la note d’information.  \n\nPour les personnes physiques résidentes fiscales belges, les intérêts perçus sont soumis à un précompte mobilier libératoire de 30%.";
      p.aboutLegalInfoText.en = "Promotional communication - Subscribing to a Fundraising campaign involves a risk of total loss of the capital invested. Before investing, please read the information note. \n\nFor natural persons resident in Belgium for tax purposes, the interest received is subject to a 30% withholding tax.";
      p.aboutLegalInfoText.nl = "Promotionele communicatie - Inschrijven op een Fondsenwerving houdt voor de belegger het risico in van volledig verlies van het geïnvesteerde kapitaal. Alvorens te investeren, gelieve de Informatienota te lezen. \n\nVoor natuurlijke personen die hun fiscale woonplaats in België hebben, is de ontvangen rente onderworpen aan een roerende voorheffing van 30%.";
      p.documentsLegalInfoText.fr = "La note descriptive est publiée par [PwC Enterprise Advisory bv] conjointement avec la Direction de BeeBonds SRL. Le seul but de cette Note Descriptive est d'aider le destinataire à décider s'il souhaite procéder à une enquête plus approfondie sur la société. Aucune déclaration ou garantie, expresse ou implicite, n'est donnée quant à (i) l'exactitude ou l'exhaustivité du contenu de cette Note Descriptive (ii) l'exactitude ou l'exhaustivité des projections incluses\n\nLa note d’information est établie par l'émetteur de l’obligation conformément à la loi du 11 juillet 2018 relative aux offres au public d’instruments de placement et aux admissions d’instruments de placement à la négociation sur des marchés réglementés.";
      p.documentsLegalInfoText.nl = "De Beschrijvende Nota is uitgegeven door [PwC Enterprise Advisory bv] in samenwerking met de directie van BeeBonds SRL. Deze Beschrijvende Nota is uitsluitend bedoeld om de ontvanger te helpen bij het nemen van een besluit over het al dan niet voortzetten van een nader onderzoek naar de onderneming. Er wordt geen enkele verklaring of garantie gegeven, uitdrukkelijk of impliciet, met betrekking tot (i) de nauwkeurigheid of volledigheid van de inhoud van dit Informatiememorandum (ii) de nauwkeurigheid of volledigheid van de opgenomen prognoses.\n\nDe Informatienota is opgesteld door de emittent van de Obligatie in overeenstemming met de Wet van 11 juli 2018 op de openbare aanbieding van beleggingsinstrumenten en de toelating van beleggingsinstrumenten tot de verhandeling op gereglementeerde markten. ";
      p.documentsLegalInfoText.en = "The Descriptive Note is issued by [PwC Enterprise Advisory bv] in conjunction with the Management of BeeBonds SRL. The sole purpose of this Descriptive Note is to assist the recipient in deciding whether to proceed with further investigation of the company. No representation or warranty, express or implied, is made as to (i) the accuracy or completeness of the contents of this Information Memorandum (ii) the accuracy or completeness of the projections included \n\nThe Information Note is prepared by the issuer of the bond in accordance with the Act of 11 July 2018 on public offers of investment instruments and admissions of investment instruments to trading on regulated markets.";
      this.setField((previousState) => previousState.project = p);
    }

  }
  componentDidUpdate() {
  }

  handleTabChange(tabName: string) {
    this.setField((previousState) => previousState.currentTab = tabName);
  }

  handleEditButtonPressed() {
    this.setField((previousState) => previousState.isEditable = !previousState.isEditable);
  }

  handleSaveButtonPressed() {
    if (this.isProjectInfoTabComplete()) {
      this.showPopUp(
        <ValidationPopUpView text={"ValidateProject"} callBack={() => {
          this.saveProject();
        }} />
      )
    } else {
      alert("La page infos projet n'est pas complète")
    }


  }

  saveProject() {
    this.state().project.duration = this.convertStringToNumber(this.state().project.durationString);
    this.state().project.annualInterestRate = this.convertStringToNumber(this.state().project.annualInterestRateString);
    this.setField((previousState) => previousState.isLoading = true);
    if (this.state().isNewProject) {
      this.ProjectsOperations.insertProject(this.state().project, () => {
        this.setField((previousState) => previousState.isLoading = false);
        this.showPopUp(
          <View style={{ marginHorizontal: 50, minWidth: 600 }}>
            <Text style={{ textAlign: 'center', color: '#4A495C', fontSize: 21, fontWeight: '600', marginTop: 50 }}>
              Projet ajouté
            </Text>
          </View>
        )
        this.NavigationManager.navigate('/dashboard/projects', true);
      })

    } else {
      this.ProjectsOperations.updateProject(this.state().project, () => {
        this.setField((previousState) => previousState.isLoading = false);
        this.showPopUp(
          <View style={{ marginHorizontal: 50, minWidth: 600 }}>
            <Text style={{ textAlign: 'center', color: '#4A495C', fontSize: 21, fontWeight: '600', marginTop: 50 }}>
              Projet modifié
            </Text>
          </View>
        )
        this.NavigationManager.navigate('/dashboard/projects', true);
      })
    }
  }



  isProjectInfoTabComplete(): boolean {
    let currentProject: ProjectDTO = this.state().project
    if (currentProject.code === ''
      || currentProject.projectName === ''
      || currentProject.coverPictureUrl === ''
      || currentProject.projectIntroduction.fr === ''
      || currentProject.projectIntroduction.nl === ''
      || currentProject.projectIntroduction.en === ''
    ) {
      return false;
    }
    return true;
  }

  save() {

  }

  getBase64(file: any, cb: Function) {
    let reader = new FileReader();
    reader.onload = function () {
      cb(reader.result)
    };
    reader.readAsDataURL(file);
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


  //Project Infos handlers
  handleProjectNameChange(projectName: string) {
    this.setField((previousState) => previousState.project.projectName = projectName);
    this.setField((previousState) => previousState.project.enterprise = projectName);
    this.setField((previousState) => previousState.project.fullCompanyName = projectName);
    this.setField((previousState) => previousState.project.consoleTitle = projectName);
  }
  handleCodeChange(code: string) {
    this.setField((previousState) => previousState.project.code = code);
  }
  handleIsPublishedChange(bool: any) {
    this.setField((previousState) => previousState.project.isPublished = bool.value);
  }
  handleTypeChange(type: any) {
    this.setField((previousState) => previousState.project.projectTypeId = type.value);
  }

  handleSafetyTypeChange(type: any) {
    this.setField((previousState) => previousState.project.safetyTypeId = type.value);
  }

  handlePlacementTypeChange(type: any) {
    this.setField((previousState) => previousState.project.placementType1Id = type.value);
  }

  handleObligationFormTypeChange(type: any) {
    this.setField((previousState) => previousState.project.placementType2Id = type.value);
  }

  handleProjectRiskCategoryChange(cat: string) {
    this.setField((previousState) => previousState.project.riskCateory = cat);
  }

  //Finances Infos handlers
  handleMinimumInvestChange(amount: string) {
    this.setField((previousState) => previousState.project.minimumInvest = parseInt(amount) >= 0 ? parseInt(amount) : 0);
  }
  handleMaximumInvestChange(amount: string) {
    this.setField((previousState) => previousState.project.maximumInvest = parseInt(amount) >= 0 ? parseInt(amount) : 0);
  }
  handleCurrentInvestChange(amount: string) {
    this.setField((previousState) => previousState.project.currentInvest = parseInt(amount) >= 0 ? parseInt(amount) : 0);
  }
  handleMinimumTicketChange(amount: string) {
    this.setField((previousState) => previousState.project.minimumTicket = parseInt(amount) >= 0 ? parseInt(amount) : 0);
  }
  handleMaximumTicketChange(amount: string) {
    this.setField((previousState) => previousState.project.maximumTicket = parseInt(amount) >= 0 ? parseInt(amount) : 0);
  }
  handleDurationChange(time: string) {
    this.setField((previousState) => previousState.project.durationString = time);
  }
  handleIssueDateChange(date: string) {
    this.setField((previousState) => previousState.project.issueDate = date.replace(/-/g, '/'));
  }

  handleDueDateChange(date: string) {
    this.setField((previousState) => previousState.project.dueDate = date.replace(/-/g, '/'));
  }

  handleOfferEndDateChange(date: string) {
    this.setField((previousState) => previousState.project.offerEndDate = date.replace(/-/g, '/'));
  }

  handleOpeningDateChange(date: string) {
    this.setField((previousState) => previousState.project.openingDate = moment(date, "DD-MM-YYYY").hours(this.state().openingHours.split(':')[0]).minutes(this.state().openingHours.split(':')[1]).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss'));

  }
  handleOpeningHoursChange(hours: string) {
    this.setField((previousState) => previousState.openingHours = hours);
    this.setField((previousState) => previousState.project.openingDate = moment(this.state().project.openingDate).hours(hours.split(':')[0]).minutes(hours.split(':')[1]).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss'));
  }
  handleAnnualInterestRateChange(rate: string) {
    this.setField((previousState) => previousState.project.annualInterestRateString = rate);
  }
  handleOverbookingRateChange(rate: string) {
    this.setField((previousState) => previousState.project.overbookingRate = parseInt(rate) >= 0 ? parseInt(rate) : 0);
  }
  handleIBANChange(IBAN: string) {
    this.setField((previousState) => previousState.project.iban = IBAN);
  }
  handleBICChange(BIC: string) {
    this.setField((previousState) => previousState.project.bic = BIC);
  }
  handleBCEChange(BCE: string) {
    this.setField((previousState) => previousState.project.bce = BCE);
  }
  handleISINChange(ISIN: string) {
    this.setField((previousState) => previousState.project.isin = ISIN);
  }
  handleBankChange(Bank: any) {
    this.setField((previousState) => previousState.selectedBank = Bank.label);
    this.setField((previousState) => previousState.project.projectBankId = Bank.value);
  }

  handleLeaderChange(leader: any) {
    this.setField((previousState) => previousState.project.leaderId = leader.value);
  }

  onLegalFormChange(form: any) {
    this.setField((previousState) => previousState.project.legalFormId = form.value);
  }

  //Company Infos handlers
  handleNameFRChange(name: string) {
    this.setField((previousState) => previousState.project.name.fr = name);
  }
  handleNameNLChange(name: string) {
    this.setField((previousState) => previousState.project.name.nl = name);
  }
  handleNameENChange(name: string) {
    this.setField((previousState) => previousState.project.name.en = name);
  }
  handleActivityDropdownChange(activity: TranslationDTO) {
    this.setField((previousState) => previousState.project.activity = activity);
    this.setField((previousState) => previousState.project.activityId = activity.id ?? 0);
  }
  handleActivityFRChange(activity: string) {
    this.setField((previousState) => previousState.project.activity.fr = activity);
  }
  handleActivityNLChange(activity: string) {
    this.setField((previousState) => previousState.project.activity.nl = activity);
  }
  handleActivityENChange(activity: string) {
    this.setField((previousState) => previousState.project.activity.en = activity);
  }
  handlestartOfActivityChange(year: string) {
    this.setField((previousState) => previousState.project.startOfActivity = parseInt(year) >= 0 ? parseInt(year) : 0);
  }
  handleStreetChange(street: string) {
    this.setField((previousState) => previousState.project.address.street = street);
  }
  handleNumberChange(number: string) {
    this.setField((previousState) => previousState.project.address.number = number);
  }
  handleBoxChange(box: string) {
    this.setField((previousState) => previousState.project.address.number = box);
  }
  handlePostCodeChange(postCode: string) {
    this.setField((previousState) => previousState.project.address.postCode = postCode);
  }
  handleLocalityChange(locality: string) {
    this.setField((previousState) => previousState.project.address.locality = locality);
  }
  handleCountryChange(country: string) {
    this.setField((previousState) => previousState.project.address.country = country);
  }
  handleWebsiteURLChange(enterpriseWebSite: string) {
    this.setField((previousState) => previousState.project.enterpriseWebSite = enterpriseWebSite);
  }
  handleFullAddressFRChange(address: string) {
    this.setField((previousState) => previousState.project.addressFR = address);
  }
  handleFullAddressNLChange(address: string) {
    this.setField((previousState) => previousState.project.addressNL = address);
  }


  //Descriptions handlers
  handleIntroductionFRChange(text: string) {
    this.setField((previousState) => previousState.project.projectIntroduction.fr = text);
  }
  handleIntroductionNLChange(text: string) {
    this.setField((previousState) => previousState.project.projectIntroduction.nl = text);
  }
  handleIntroductionENChange(text: string) {
    this.setField((previousState) => previousState.project.projectIntroduction.en = text);
  }

  handleDescriptionFRChange(text: string) {
    this.setField((previousState) => previousState.project.projectDescription.fr = text);
  }
  handleDescriptionNLChange(text: string) {
    this.setField((previousState) => previousState.project.projectDescription.nl = text);
  }
  handleDescriptionENChange(text: string) {
    this.setField((previousState) => previousState.project.projectDescription.en = text);
  }
  handleAboutLegalTextFRChange(text: string) {
    this.setField((previousState) => previousState.project.aboutLegalInfoText.fr = text);
  }
  handleAboutLegalTextNLChange(text: string) {
    this.setField((previousState) => previousState.project.aboutLegalInfoText.nl = text);
  }
  handleAboutLegalTextENChange(text: string) {
    this.setField((previousState) => previousState.project.aboutLegalInfoText.en = text);
  }

  handleDocumentLegalTextFRChange(text: string) {
    this.setField((previousState) => previousState.project.documentsLegalInfoText.fr = text);
  }
  handleDocumentLegalTextNLChange(text: string) {
    this.setField((previousState) => previousState.project.documentsLegalInfoText.nl = text);
  }
  handleDocumentLegalTextENChange(text: string) {
    this.setField((previousState) => previousState.project.documentsLegalInfoText.en = text);
  }


  handleHeaderFRChange(text: string) {
    this.setField((previousState) => previousState.project.projectHeader.fr = text);
  }
  handleHeaderNLChange(text: string) {
    this.setField((previousState) => previousState.project.projectHeader.nl = text);
  }
  handleHeaderENChange(text: string) {
    this.setField((previousState) => previousState.project.projectHeader.en = text);
  }

  handleSafetyDescriptionFRChange(text: string) {
    this.setField((previousState) => previousState.project.safetyDescription.fr = text)
  }
  handleSafetyDescriptionNLChange(text: string) {
    this.setField((previousState) => previousState.project.safetyDescription.nl = text)
  }
  handleSafetyDescriptionENChange(text: string) {
    this.setField((previousState) => previousState.project.safetyDescription.en = text)
  }
  handleVisibleChange(bool: any) {
    this.setField((previousState) => previousState.project.isVisible = bool.value)
  }
  handleIsSafety(bool: any) {
    this.setField((previousState) => previousState.project.isSafety = bool.value)
  }

  handleCommentChange(text: string) {
    this.setField((previousState) => previousState.project.comment = text)
  }

  //Multimedia handlers
  handlePreviewPictureChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.previewPictureData = result.split('base64,')[1]) });
  }
  handleLeftMenuPictureUrlChange(text: string) {
    this.setField((previousState) => previousState.project.leftMenuPictureUrl = text);
  }
  handleCoverPictureChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.coverPictureData = result.split('base64,')[1]) });
  }
  handleLogoPictureChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.logoPictureData = result.split('base64,')[1]) });
  }
  handleVideoUrlChange(text: string) {
    this.setField((previousState) => previousState.project.videoUrl = text);
  }
  handlePdfFRChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.confirmationPdfFRData = result.split('base64,')[1]) });
  }
  handlePdfNLChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.confirmationPdfNLData = result.split('base64,')[1]) });
  }

  handleCertificateLogoFileChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.certificateLogoFileData = result.split('base64,')[1]) });
  }

  handleCertificateSignatureFileChange(file: File) {
    this.getBase64(file, (result: any) => { this.setField((previousState) => previousState.project.certificateSignatureFileData = result.split('base64,')[1]) });
  }

  //Project Benefits handler
  handleBenefitsOrderChange(order: number, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].order = order);
  }
  handleBenefitsTitleFRChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].title.fr = text);
  }
  handleBenefitsTitleNLChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].title.nl = text);
  }
  handleBenefitsTitleENChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].title.en = text);
  }
  handleBenefitsSubtitleFRChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].subtitle.fr = text);
  }
  handleBenefitsSubtitleNLChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].subtitle.nl = text);
  }
  handleBenefitsSubtitleENChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectBenefits[index].subtitle.en = text);
  }

  //Project Questions handler
  handleQuestionsOrderChange(order: number, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].order = order);
  }
  handleQuestionsTitleFRChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].title.fr = text);
  }
  handleQuestionsTitleNLChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].title.nl = text);
  }
  handleQuestionsTitleENChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].title.en = text);
  }
  handleQuestionsResponseFRChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].response.fr = text);
  }
  handleQuestionsResponseNLChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].response.nl = text);
  }
  handleQuestionsResponseENChange(text: string, index: number) {
    this.setField((previousState) => previousState.project.projectQuestions[index].response.en = text);
  }

  convertStringToNumber(input: string) {
    if (!input)
      return 0;
    // Remplacer la virgule par un point pour s'assurer que le nombre soit correct
    const cleanedInput = input.replace(',', '.');

    // Convertir la chaîne en nombre
    const number = parseFloat(cleanedInput);

    // Vérifier si la conversion a réussi
    if (!isNaN(number)) {
      return number;
    } else {
      return 0;
    }
  }
  callback(project: ProjectDTO) {
    this.setField((previousState) => previousState.project = project);
  }
}
