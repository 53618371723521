import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import FinanceInfosViewState from './FinanceInfosViewState';
import FinanceInfosViewProps from './FinanceInfosViewProps';
import FinanceInfosViewStyle from './FinanceInfosViewStyle';
import FinanceInfosViewModel from './FinanceInfosViewModel';
import { View } from 'react-native';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import { TranslationView } from '@levelapp/softfabric-ui';
import moment from 'moment';
import DateSelect from '../../../../../../../../../../../../Components/Select/DateSelect';
import HoursSelect from '../../../../../../../../../../../../Components/Select/HoursSelect';
import Select from '../../../../../../../../../../../../Components/Select/Select';

export default class FinanceInfosView extends ContentView<FinanceInfosViewProps, FinanceInfosViewState, FinanceInfosViewModel, FinanceInfosViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: FinanceInfosViewProps) {
    super(props);

    // Binding
    this.bind(new FinanceInfosViewModel(this), new FinanceInfosViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { title, containerSameLine, inputInline, inputDate, inputHours } = this.style
    return (
      <View style={{ zIndex: -1 }}>
        <TranslationView style={title}>project.financial</TranslationView>
        <View style={inputInline}>
          <InputView
            subscribe={() => { }}
            shouldCheck={false}
            inputClass="col-lg-7"
            label={"ISIN"}
            labelClass="col-lg-7"
            value={this.props.projet.isin}
            onChange={this.props.onISINChange}
            disabled={!this.props.isEditable}
            required={false}
            type="text" />
        </View>

        <View style={containerSameLine}>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              isValueValid={this.props.projet.minimumInvest > 0}
              inputClass="col-lg-7"
              label={"Montant minimum à Émettre "}
              labelClass="col-lg-7"
              value={this.props.projet.minimumInvest.toString()}
              onChange={this.props.onMinimumInvestChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              isValueValid={this.props.projet.maximumInvest > 0}
              inputClass="col-lg-7"
              label={"Montant maximum à Émettre "}
              labelClass="col-lg-7"
              value={this.props.projet.maximumInvest.toString()}
              onChange={this.props.onMaximumInvestChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <View style={[inputInline, { zIndex: 9999 }]}>
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              selectClass="col-lg-7"
              label={'Type de placement'}
              labelClass="col-lg-7"
              onChange={this.props.onPlacementTypeChange}
              disabled={!this.props.isEditable}
              required={false}
              value={this.state.placementTypes1.find(x => x.id === this.props.projet.placementType1Id)?.Title}
              options={this.state.placementTypes1.length > 0 && this.state.placementTypes1.map((placementType: any, key) => { return { label: placementType.Title, value: placementType.id, key } })} />
          </View>

          <View style={[inputInline, { zIndex: 500 }]}>
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              selectClass="col-lg-7"
              label={'Forme des Obligations'}
              labelClass="col-lg-7"
              onChange={this.props.onObligationFormTypeChange}
              disabled={!this.props.isEditable}
              required={false}
              value={this.state.placementTypes2.find(x => x.id === this.props.projet.placementType2Id)?.Title}
              options={this.state.placementTypes2.length > 0 && this.state.placementTypes2.map((placementType: any, key) => { return { label: placementType.Title, value: placementType.id, key } })} />
          </View>

        <View style={containerSameLine}>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Coupure "}
              labelClass="col-lg-7"
              value={this.props.projet.minimumTicket.toString()}
              onChange={this.props.onMinimumTicketChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Maximum (montant max d'invest)"}
              labelClass="col-lg-7"
              value={this.props.projet.maximumTicket.toString()}
              onChange={this.props.onMaximumTicketChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <View style={containerSameLine}>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Taux Intérêt Brut"}
              labelClass="col-lg-7"
              value={this.props.projet.annualInterestRateString}
              onChange={this.props.onAnnualInterestRateChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Durée (en année)"}
              labelClass="col-lg-7"
              value={this.props.projet.durationString}
              onChange={this.props.onDurationChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>


        {!this.state.isLoading &&
          <View >
            <View style={[containerSameLine, { zIndex: 40 }]} >
              <View style={inputDate}>
                <DateSelect
                  subscribe={() => { }}
                  shouldCheck={false}
                  selectClass="col-lg-7"
                  label={"Date d'ouverture"}
                  labelClass="col-lg-7"
                  onChange={this.props.onOpeningDateChange}
                  required={false}
                  disabled={!this.props.isEditable}
                  value={this.props.isNewProject ? '' : this.props.projet.openingDate.toString().split('T')[0].split('-').reverse().join('/')}
                  startYear={moment().year()}
                  endYear={moment().year() + 10} />
              </View>
              <View style={inputHours}>
                <HoursSelect
                  subscribe={() => { }}
                  shouldCheck={false}
                  selectClass="col-lg-7"
                  label={"Heure d'ouverture"}
                  labelClass="col-lg-7"
                  onChange={this.props.onOpeningHoursChange}
                  required={false}
                  disabled={!this.props.isEditable}
                  value={this.props.isNewProject ? '' : this.props.projet.openingDate.toString().split('T')[1].split(':').slice(0, 2).join(':')} />
              </View>
            </View>
            <View style={[inputDate, { zIndex: 30 }]}>
              <DateSelect
                subscribe={() => { }}
                shouldCheck={false}
                selectClass="col-lg-7"
                label={"Date d'émission"}
                labelClass="col-lg-7"
                onChange={this.props.onIssueDateChange}
                required={false}
                disabled={!this.props.isEditable}
                value={`${this.props.projet.issueDate}`}
                startYear={moment().year()}
                endYear={moment().year() + 10} />
            </View>
            <View style={[inputDate, { zIndex: 20 }]}>
              <DateSelect
                subscribe={() => { }}
                shouldCheck={false}
                selectClass="col-lg-7"
                label={"Date d'échéance"}
                labelClass="col-lg-7"
                onChange={this.props.onDueDateChange}
                required={false}
                disabled={!this.props.isEditable}
                value={`${this.props.projet.dueDate}`}
                startYear={moment().year()}
                endYear={moment().year() + 10} />
            </View>
            <View style={[inputDate, { zIndex: 10 }]}>
              <DateSelect
                subscribe={() => { }}
                shouldCheck={false}
                selectClass="col-lg-7"
                label={"Date de fin des souscriptions (Prolongation)"}
                labelClass="col-lg-7"
                onChange={this.props.onOfferEndDateChange}
                required={false}
                disabled={!this.props.isEditable}
                value={`${this.props.projet.offerEndDate}`}
                startYear={moment().year()}
                endYear={moment().year() + 10} />
            </View>
          </View>}


      </View>
    );
  }
}
